require('./scroll-slider');
require('./swipe-slider');
require('./rslider');
require('./hero-slider');
require('./about-slider');
require('./infrastructure-slider');
require('./advantages-slider');
require('./advantages-scroll-slider');
require('./advantages-accordion-slider');
require('./pipiline-slider');
require('./advantages-infra-slider')