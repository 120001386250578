require('../scss/app.scss');
require('./svg/svg.js');

require('../fonts/Montserrat/stylesheet.css');

window.noZensmooth = true;

require('../vue/app');
require('@vueform/slider/themes/default.css')

require('./sliders/sliders.js');

require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/switcher');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/text-wrap-creator');
require('./common/print');
require('./common/calltouch');
require('./common/fav-success');
require('./common/disable-submit-buttons');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone.js');
require('./field/floating.js');

require('./genplan/index');
require('./filters/project-filters');

require('./project-map/index');
require('./genplan/changer');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');

require('fslightbox');

require('./header/index');

require('./about-project-menu/index');

require('./balloon/index');

require('./genplan/genplan-floor-modal');

require('./advantage-bricks/index');
require('./modal-share-handler/index');
require('./modal-share-handler/share-init');

require('./pop-up/index');
require('./timer/index');

require('./parallax/index');
require('./textOver');
require('./preloader/index')

require('../../src/GlobalFav/Static/global-fav');
