class ImageObject {

    constructor(container, colorBlock, spaceHeight) {
        this.container = container;
        this.target = {
            offsetY: 0,
            scale: 1,
            opacity: 1
        };
        this.current = {
            offsetY: 0,
            scale: 1,
            opacity: 0.1
        };
        this.opacity = 0.1
        this.ease = 0.3;
        this.started = false;
        this.colorBlock = colorBlock;
        this.spaceHeight = spaceHeight;
        this.textTransform = 0;
    }

    render() {
        this.lerp();
        this.updateCoords();
        window.requestAnimationFrame(this.render.bind(this));
    }

    lerp() {
        // this.current.transform += (this.textTransform + this.current.offsetY) * 0.2;
        this.current.offsetY += (this.target.offsetY - this.current.offsetY) * this.ease;
        this.current.scale += (this.target.scale - this.current.scale) * this.ease;
    }

    updateCoords() {
        if (this.current.scale < 1.5) {
            this.container.style.transform = `scale(${this.current.scale})`;
        }
        if (this.current.opacity > 0.1) {
            this.colorBlock.style.background = `rgba(35, 35, 35, ${this.current.opacity})`;
        } else {
            this.colorBlock.style.background = '#EEEEED';
        }
    }

    update() {
        if (this.started === false) {
            this.started = true;
            this.render();
        }
        const rect = this.container.getBoundingClientRect();
        let posElemScreenTop = 0;
        let posElemScreenBottom = rect.bottom;

        let clientHeight = this.container.clientHeight + this.spaceHeight;
        let fullHeight = window.innerHeight + clientHeight;

        let offsetScroll = window.scrollY;
        if (offsetScroll < this.spaceHeight) {
            this.current.opacity = offsetScroll / this.spaceHeight;
        }

        // if (offsetScroll < (this.container.offsetHeight - 100)) {
        //     this.textTransform = offsetScroll;
        // }
        // максимальный относительный сдвиг при скролле
        let strafe = clientHeight * this.container.dataset.speed / 100;

        // максимальный зум
        let scale = 1;

        //начальный сдвиг
        let delta = 0;
        if (this.container.dataset.delta) {
            delta = clientHeight * this.container.dataset.delta / 100;
        }

        //сдвиг при скролле
        if (strafe > 0) {
            if (posElemScreenBottom > 0 && (posElemScreenTop > (0 - clientHeight))) {
                let offset = strafe * (1 - (Math.pow(posElemScreenBottom, 1.05) + clientHeight) / fullHeight) - delta;

                let currentScale = 1;

                if (this.container.dataset.scale) {
                    // уменьшается при скролле вниз
                    scale = this.container.dataset.scale;

                    if (scale > 0) {
                        let percent = (-clientHeight + posElemScreenBottom) / window.innerHeight;
                        if (percent > 0) {
                            percent = Math.pow(percent, 2);
                        } else {
                            percent = 0 - Math.pow(percent, 2);
                        }
                        currentScale = 1 + (scale - 1) * percent;
                        if (currentScale > scale) {
                            currentScale = scale;
                        }
                        if (currentScale < 1) {
                            currentScale = 1;
                        }
                    } else {
                        // увеличивается при скролле вниз
                        scale = -scale;
                        let percent = (-clientHeight + posElemScreenBottom) / window.innerHeight;
                        if (percent > 0) {
                            percent = Math.pow(percent, 2);
                        } else {
                            percent = 0 - Math.pow(percent, 2);
                        }
                        currentScale = 1 + (1 - scale) * percent;
                        if (currentScale > scale) {
                            currentScale = scale;
                        }
                        if (currentScale < 1) {
                            currentScale = 1;
                        }
                    }
                }

                this.target.offsetY = offset;
                this.target.scale = currentScale;
            }
        } else {
            if (posElemScreenBottom > 0 && (posElemScreenTop > (0 - clientHeight))) {
                let offset = strafe * (1 - (posElemScreenTop + clientHeight) / fullHeight) + delta;
                // this.container.style.transform = `translate3d(0, ${offset}px, 0)`;
                this.target.offsetY = offset;
                this.target.scale = 1;
            }
        }

    }
}

export default ImageObject;