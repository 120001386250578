// Сюда просим идентификатор счетчика
// Именно с ним и вставляется код на страницу - можно его там увидеть,
// Ну либо попросить у менеджера

const callTouchModId = '63h0jyu4';

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

window.addEventListener('DOMContentLoaded', () => {

  setTimeout(() => {
    let val = '';

    const callTouchData = window.ct('calltracking_params', callTouchModId);

    if (callTouchData && callTouchData.sessionId) {
      val = callTouchData.sessionId;
    }
    if (!val) {
      val = '';
    }
    setCookie('callTouchID', val, 365);
  }, 5000);
});