import {onDomChanges, onDomReady} from "../components/dynamic/observer";
function init() {
    const container = document.querySelector('[data-hero]');
    const over = container.querySelector('[data-over]');
    const block = container.querySelector('[data-block]');

    countSize(block, over);
    window.addEventListener('scroll', () => {
        countSize(block, over);
    });
}

function countSize(block, over) {
    // Medium screen
    if (window.innerWidth < 1199 && window.innerWidth > 768) {
        const height = block.getBoundingClientRect().y - 90;
        over.style.height = `${height}px`;
        // Small screen
    } else if (window.innerWidth < 768) {
        const height = block.getBoundingClientRect().y - 65;
        over.style.height = `${height}px`;
    } else {
        // Up large
        let height = block.getBoundingClientRect().y - 125;
        if (height < 0) {
            height = 0;
        }
        over.style.height = `${height}px`;
    }
}

onDomReady(() => {
    init();
})

onDomChanges(() => {
    init()
})

