import {onDomReady} from "../../components/dynamic/observer";
import ImageObject from './objects/ImageObject'

class Parallax {
    objects = [];
    offsetY = 0;
    lastOffsetY = null;

    constructor() {
        this.collect();
        this.eventListeners();
        this.update();
    }

    collect() {
        this.objects = [];
        const heroBlock = document.querySelector('[data-hero]');
        const colorBlock = document.querySelector('[data-color-block]');
        Array.from(document.querySelectorAll('[data-parallax]')).forEach((container) => {
            const spaceHeight = heroBlock.offsetHeight - container.offsetHeight;
            this.objects.push(new ImageObject(container, colorBlock, spaceHeight));
        });
    }

    eventListeners() {
        const instance = this;
        instance.offsetY = window.pageYOffset;
        document.addEventListener('scroll', (event) => {
            instance.offsetY = window.pageYOffset;
        });
    }

    update() {
        if (this.lastOffsetY !== this.offsetY) {
            this.objects.forEach((object) => {
                object.update();
            });

            this.lastOffsetY = this.offsetY
        }

        window.requestAnimationFrame(this.update.bind(this));
    }
}

function init() {
    const parallax = new Parallax();
}

onDomReady(() => {
    init();
});