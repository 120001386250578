import {onDomReady} from "../../components/dynamic/observer";

class AboutProjectMenu {
  constructor(selector) {
    this.menu = selector;
    this.menuContainer = this.menu.parentElement;
    this.contentBlock = this.menuContainer.parentElement.parentElement;
    this.items = Array.from(this.menu.querySelectorAll('[data-about-link]'));
    this.contents = this.items.map((item) => {
      const contentItemId = item.getAttribute('href').slice(1, item.getAttribute('href').length);
      return document.getElementById(contentItemId);
    });
    this.activeIndex = 0;
    this.scrollTop = 0;
    this.lastScrollTop = 0;

    const header = document.querySelector('[data-header]');
    this.headerheight = parseInt(header.offsetHeight);

    this.eventListeners();
  }

  handleContentsPositions() {
    this.contents.forEach((content, index) => {
      if ((content.getBoundingClientRect().top - window.innerHeight * 0.4) < 0 && index > this.activeIndex) {
        this.activeIndex = index;

        if (this.items[this.activeIndex - 1]) {
          this.slide(this.items[this.activeIndex - 1], 'add');
        }
      }

      if ((content.getBoundingClientRect().top - window.innerHeight * 0.4) > 0 && index <= this.activeIndex) {
        if ((index - 1) >= 0) {
          this.activeIndex = index - 1;

          this.slide(this.items[this.activeIndex], 'sub');
        }
      }
    });
  }

  // Стики поведение табов на таблетке и мобилке
  handleTabs() {
    if (window.innerWidth < 1200) {
      this.menuContainer.parentElement.style.height = `${this.menuContainer.offsetHeight}px`;

      const contentBlockTop = this.contentBlock.getBoundingClientRect().top + this.scrollTop;
      const contentBlockHeight = this.contentBlock.offsetHeight;
      const contentBlockBottom = contentBlockTop + contentBlockHeight;
      if (this.scrollTop > contentBlockTop) {
        this.menuContainer.classList.add('_sticky');
      } else {
        this.menuContainer.classList.remove('_sticky');
      }

      if (this.scrollTop + this.menuContainer.offsetHeight > contentBlockBottom) {
        const offset = (this.scrollTop + this.menuContainer.offsetHeight) - contentBlockBottom;
        this.menuContainer.style.top = `-${offset}px`;
      } else {
        this.menuContainer.style.top = '';
      }

      if (this.scrollTop > contentBlockTop  && this.scrollTop < this.lastScrollTop) {
        this.menuContainer.style.transform = `translate3d(0, ${this.headerheight}px, 0)`;
      } else {
        this.menuContainer.style.transform = '';
      }
    }
  }

  setInactiveItems() {
    this.items.map((item) => item.parentElement.classList.remove('_active'));
  }

  setActiveItem() {
    const currentItem = this.items[this.activeIndex];
    if (currentItem.parentElement.classList.contains('_active')) {
      return;
    }

    this.setInactiveItems();
    currentItem.parentElement.classList.add('_active');
  }

  slide(item, operator) {
    const container = this.menu;
    let scrollAmount = 0;

    const slideTimer = setInterval(function() {
      const bounds = item.getBoundingClientRect();

      if (operator === 'add') {
        container.scrollLeft += 10;
      } else if (operator === 'sub') {
        container.scrollLeft -= 10;
      }

      scrollAmount += 10;
      if (scrollAmount >= bounds.width) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  }

  eventListeners() {
    window.addEventListener('scroll', () => {
      this.scrollTop = window.scrollY;
      this.handleContentsPositions();
      this.setActiveItem();
      this.handleTabs();
      this.lastScrollTop = this.scrollTop;
    });
  }
}

onDomReady(() => {
  const menuEl = document.querySelector('[data-about-menu]');
  if (menuEl) {
    new AboutProjectMenu(menuEl);
  }
})